@import "src/styles/common.scss";

$button-bg: #ff5f67;
$button-text-color: #fff;

.button {
    display: flex;
    font-size: 16px;
    height: 35px;
    color: $secondary-color;
    align-items: center;
    cursor: pointer;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    width: auto;
    padding: 0px 20px;
    justify-content: center;
    margin: 0px 10px;
    border-radius: 10px;

    &:focus {
        outline: 0;
    }

    &:active,
    &:hover {
        transform: scale(0.9);
        background-color: $button-bg;
        color: $button-text-color;
        transition: 1s;
    }

    &--active {
        justify-content: center;
        transition: 1s;
        padding: 0px 20px;
        color: $button-text-color;
        background-color: $button-bg;
    }
}

.button2 {
    display: flex;
    font-size: 16px;
    height: 50px;
    color: $secondary-color;
    align-items: center;
    cursor: pointer;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    width: auto;
    padding: 0px 20px;
    justify-content: center;
    margin: 0px 20px;
    border-radius: 10px;
    border: 2px solid #b0958e;

    &:focus {
        outline: 0;
    }

    &:active,
    &:hover {
        transform: scale(0.95);
        background-color: $secondary-color;
        color: $button-text-color;
        transition: 1s;
    }

    &--active {
        justify-content: center;
        transition: 1s;
        padding: 0px 20px;
        color: $button-text-color;
        background-color: $button-bg;
    }
}
