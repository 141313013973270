@import "src/styles/common.scss";

.footer {
    height: 100%;
    bottom: 0;
    &__info {
        display: flex;
        position: relative;
        width: 70%;
        margin: 0 auto;
        color: white;
        font-size: 18px;
        min-height: 100px;
        height: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-weight: bold;

        &-bg {
            background-color: #582014;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100px;
            top: 0;
            filter: drop-shadow(0px 0px 5px rgb(170, 139, 81, 0.2));
        }
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        margin: 30px;
        column-gap: 30px;
        flex-wrap: wrap;
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffa200;
            margin-top: 150px;
            min-height: 100px;
            position: absolute;
            flex-direction: column;
            padding-bottom: 20px;
            left: 0;
            width: 100%;
            filter: drop-shadow(0px 0px 5px rgb(170, 139, 81, 0.2));
        }

        &-col {
            display: flex;
            height: 240px;
            margin-bottom: 20px;
            flex-wrap: wrap;
            &:nth-child(1) {
                flex: 15%;
            }
            &:nth-child(2) {
                flex: 15%;
            }
            &:nth-child(3) {
                flex: 20%;
                flex-direction: column;
            }
        }

        &-title {
            font-family: "Arial Black", Gadget, sans-serif;
            font-size: 18px;
            margin-bottom: 10px;
            color: $secondary-color;
        }

        &-list {
            display: flex;
            flex-direction: column;
            font-family: "Arial Black", Gadget, sans-serif;
            font-size: 16px;
            color: $main-color;
            width: 100%;
            a {
                margin: 2px 0px;
            }
        }

        &-address {
            display: flex;
            max-width: 375px;
            background-color: white;
            border: 1px solid $secondary-color;
            border-radius: 40px;
            color: $secondary-color;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 10px;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            text-decoration: none;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            &-wrapper {
                display: flex;
                flex-direction: column;
                margin: 5px 0px;
                width: 100%;
            }
            &-title {
                font-family: "Arial Black", Gadget, sans-serif;
                color: $secondary-color;
            }
        }
    }

    &__coin {
        display: flex;
        flex: 10%;
        max-width: 200px;
        &:hover {
            -webkit-animation: rotate-scale-up 1s linear both;
            animation: rotate-scale-up 1s linear both;
        }
    }
}

@-webkit-keyframes rotate-scale-up {
    0% {
        -webkit-transform: scale(1) rotateZ(0);
        transform: scale(1) rotateZ(0);
    }
    50% {
        -webkit-transform: scale(1.1) rotateZ(180deg);
        transform: scale(1.1) rotateZ(180deg);
    }
    100% {
        -webkit-transform: scale(1) rotateZ(360deg);
        transform: scale(1) rotateZ(360deg);
    }
}
@keyframes rotate-scale-up {
    0% {
        -webkit-transform: scale(1) rotateZ(0);
        transform: scale(1) rotateZ(0);
    }
    50% {
        -webkit-transform: scale(1.1) rotateZ(180deg);
        transform: scale(1.1) rotateZ(180deg);
    }
    100% {
        -webkit-transform: scale(1) rotateZ(360deg);
        transform: scale(1) rotateZ(360deg);
    }
}

@media (max-width: $p-mobile) {
}

@media (max-width: $tablet) {
    .footer {
        &__info {
            display: flex;
            font-size: 14px;
            height: 140px;
            width: 90%;
            margin: auto;
            justify-content: center;
            &-bg {
                height: 150px;
            }
        }

        &__links {
            width: 90%;
            &-col {
                &:nth-child(1) {
                    flex: 40%;
                }
                &:nth-child(2) {
                    flex: 40%;
                }
                &:nth-child(3) {
                    flex: 100%;
                    width: 100%;
                }
            }

            &-list {
                font-size: 16px;
            }
        }

        &__coin {
            display: none;
        }
    }
}

@media (max-width: $desktop) {
    .footer {
    }
}
