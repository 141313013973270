@import "src/styles/common.scss";

.spinner-container {
    z-index: 9999;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: var(--theme-page-background);
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-image {
    border-radius: 50%;
    height: 10%;
    border: 15px double var(--theme-button-border);
    filter: drop-shadow(0 0 40px var(--theme-button-background));
    -webkit-animation: rotation 3s infinite linear;
    animation: rotation 3s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
