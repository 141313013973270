@import "src/styles/common.scss";

.news {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 0px 20px;
    &__wrapper {
        h2 {
            justify-content: center;
        }
    }

    &__container {
        display: flex;
        column-gap: 30px;
    }
    &__image-wrapper {
        display: flex;
        width: 30%;
        img {
            animation: float 6s ease-in-out infinite;
        }
    }

    &__news-container {
        display: flex;
        width: 100%;
        background: white;
        box-shadow: inset 0px 0px 15px #ddcaa9;
        height: 100%;
        overflow: hidden;
    }
    &__news-wrapper {
        overflow-y: scroll;
        max-height: 800px;
        display: flex;
        flex-direction: column;
        width: 100%;
        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: rgb(226, 226, 226);
        }

        &::-webkit-scrollbar-thumb {
            background: $secondary-color;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    &__description-wrapper {
        display: flex;
        font-size: 20px;
        flex-direction: column;
        margin: 20px;
        img {
            height: 100%;
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        text-align: justify;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-family: "Arial Black", Gadget, sans-serif;
            filter: drop-shadow(0px 4px 0px #ffe4b2);
            display: flex;
            align-items: center;
            height: 100%;
            margin: 20px 0px;
        }
    }

    &__news-footer-wrapper {
        width: 100%;
        display: flex;
        margin: 20px 0px;
        justify-content: center;
        flex-direction: column;
    }

    &__news-image-wrapper {
        display: flex;
        max-height: 250px;
        justify-content: center;
    }

    &__news-button-wrapper {
        margin: 20px 0px;
        display: flex;
        justify-content: center;
        height: 50px;
    }

    &__description {
        height: 100%;
    }
}

@keyframes float {
    0% {
        transform: rotate(-1deg);
    }
    50% {
        transform: rotate(1deg);
    }
    100% {
        transform: rotate(-1deg);
    }
}

@media (max-width: $p-mobile) {
}

@media (max-width: $tablet) {
    .news {
        &__container {
            flex-direction: column;
        }

        &__title-wrapper {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        &__image-wrapper {
            display: flex;
            width: 100%;
            justify-content: center;
            margin: 30px 0px;
            img {
                height: 50%;
            }
        }

        &__description-wrapper {
            margin: 0px 10px;
            font-size: 18px;
        }

        &__news-wrapper {
            height: 100%;
        }

        &__news-image-wrapper {
            max-height: 100%;
        }

    }
    img {
        width: 50%;
    }
}

@media (max-width: $desktop) {
    .news {
    }
}
