@import "src/styles/common.scss";

.how-to-buy {
    &__container {
        display: flex;
        flex-direction: column;
        height: 600px;
        margin: 100px 0px 50px 0px;
        h2 {
            justify-content: center;
        }
    }

    &__background {
        overflow: hidden;
        height: 600px;
        position: absolute;
        width: 100%;
        background-color: white;
        background-image: url("how-to-buy-bg.png");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        left: 0;
        filter: drop-shadow(0px 0px 5px rgba(170, 139, 81, 0.3));
    }

    &__label {
        display: flex;
        font-size: 22px;
        width: 100%;
        justify-content: center;
        text-align: center;
        position: relative;
        white-space: pre;
        flex-wrap: wrap;
        a {
            color: #4f83e2;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__stickers {
        display: flex;
        flex: 35%;
        align-items: center;
        justify-content: center;
        &:nth-child(1) {
            flex: 25%;
        }
        &-wrapper {
            display: flex;
            height: 100%;
            position: relative;
        }
        &-sticker {
            border-radius: 32px;
            filter: drop-shadow(0px 3px 5px rgb(162, 162, 162, 0.6));
            height: 60%;
            width: 100%;
            display: flex;
            background: white;
            margin: 0px 30px;
            flex-direction: column;
            align-items: center;
            font-size: 20px;
            text-align: center;
            padding: 20px;
        }

        &-text-wrapper {
            display: flex;
            flex-direction: column;
            height: 200px;
            justify-content: space-evenly;
        }

        &-title {
            font-weight: bold;
        }

        &-description {
            font-size: 18px;
        }

        img {
            width: 100%;
            margin-right: 30px;
            &:hover {
                -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
                animation: heartbeat 1.5s ease-in-out infinite both;
            }
        }
    }

    &__line {
        display: flex;
        align-items: center;
        height: 50%;
        flex: 1%;
        border-bottom: 3px solid $secondary-color;
    }
}

@media (max-width: $p-mobile) {
    a {
        img {
            width: 100%;
        }
    }
}

@media (max-width: $tablet) {
    .how-to-buy {
        &__container {
            height: 100%;
            position: relative;
        }

        &__background {
            height: 100%;
            padding: 0px 40px;
            left: -40px;
        }

        &__stickers {
            &:nth-child(1) {
                justify-content: center;
            }

            &-wrapper {
                flex-direction: column;
                margin: 30px 0px;
            }

            &-sticker {
                margin: 30px 0px;
            }

            img {
                margin: 0px;
                width: 50%;
            }
        }

        &__line {
            display: none;
        }
    }
}

@media (max-width: $desktop) {
    .how-to-buy {
    }
}
