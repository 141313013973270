@import "src/styles/common.scss";

.header {
    font-size: 20px;
    padding: 100px 0px;

    &__column {
        flex: 50%;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &:nth-child(1) {
            flex: 55%;
        }
        &:nth-child(2) {
            flex: 45%;
        }
    }

    &__info {
        display: flex;
        height: 110px;
        flex: 30%;
        border-radius: 27px;
        box-shadow: inset 0px 0px 20px rgb(52, 42, 1, 0.4);
        flex-direction: column;
        color: white;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        span {
            margin: 5px 0px;
        }
        &-description {
            font-family: "Arial Black", Gadget, sans-serif;
        }
        &:nth-child(1) {
            background-color: #eaca3e;
        }
        &:nth-child(2) {
            background-color: #ffbc47;
        }
        &:nth-child(3) {
            background-color: #e77f8e;
        }
        &-wrapper {
            display: flex;
            width: calc(100% - 40px);
            background: linear-gradient(180deg, rgba(255, 243, 221, 1) 0%, rgba(245, 227, 193, 1) 100%);
            border-radius: 39px;
            column-gap: 10px;
            padding: 20px;
            box-shadow: inset 0px 0px 15px rgb(221, 202, 169);
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        .span {
            margin: 30px;
        }
        &--orange {
            color: #ff8400;
        }
        &--bold {
            font-weight: bold;
        }
        &--gap {
            margin: 20px 0px;
        }
    }

    &__akita-wrapper {
        justify-content: center;
        display: flex;
    }

    &__scan {
        &-button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 2px solid #d3b06e;
            border-radius: 18px;
            font-size: 16px;
            padding: 5px 10px;
            flex: 50%;
            a {
                color: $secondary-color;
            }
            img {
                margin-right: 10px;
            }
            &:hover {
                background-color: $secondary-color;
                transition: 1s;
                a {
                    color: $main-color;
                    transition: 1s;
                }
                cursor: pointer;
            }
        }
        &-buttons-wrapper {
            margin: 20px 0px;
            column-gap: 20px;
            display: flex;
            width: 100%;
            justify-content: space-around;
        }
    }

    &__buy {
        &-button-1 {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
            font-size: 18px;
            background: white;
            flex: 50%;
            height: 75px;
            position: relative;
            font-family: "Arial Black", Gadget, sans-serif;
            filter: drop-shadow(0px 5px 4px rgba(170, 139, 81, 0.5));
            cursor: pointer;
            a {
                color: $secondary-color;
            }
            &-icon {
                position: absolute;
                height: 70%;
                width: 30px;
                background-color: #121212;
                border-radius: 18px;
                right: 10px;
                &::after {
                    content: "\25BC";
                    height: 100%;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }
            }
            &:hover {
                background-color: black;
                color: white;
                transition: 1s;
            }
            &:hover a {
                color: white;
                transition: 1s;
            }
            &:hover .header__buy-button-1-icon {
                background-color: white;
                &::after {
                    color: black;
                }
                transform: translateX(-250px);
                transition: 0.5s;
            }
            img {
                margin-right: 10px;
            }
        }
        &-button-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
            font-size: 18px;
            background: white;
            flex: 50%;
            height: 75px;
            position: relative;
            font-family: "Arial Black", Gadget, sans-serif;
            filter: drop-shadow(0px 5px 4px rgba(170, 139, 81, 0.5));
            cursor: pointer;
            a {
                color: $secondary-color;
            }
            &-icon {
                position: absolute;
                height: 70%;
                width: 30px;
                background-color: #ed4552;
                border-radius: 18px;
                right: 10px;
                &::after {
                    content: "\25BC";
                    height: 100%;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }
            }
            &:hover {
                background-color: #ed4552;
                color: white;
                transition: 1s;
            }
            &:hover a {
                color: white;
                transition: 1s;
            }
            &:hover .header__buy-button-2-icon {
                background-color: white;
                &::after {
                    color: #ed4552;
                }
                transform: translateX(-250px);
                transition: 0.5s;
            }
            img {
                margin-right: 10px;
            }
        }
        &-buttons-wrapper {
            margin: 20px 0px;
            column-gap: 20px;
            display: flex;
            width: 100%;
            justify-content: space-around;
        }
    }

    &__socials-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        a {
            margin: 0px 10px;
            &:hover {
                -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
                animation: heartbeat 1.5s ease-in-out infinite both;
            }
        }
    }

    &__akita-img {
        animation: float 6s ease-in-out infinite;
    }

    .column {
        flex-direction: column;
    }
}

@keyframes float {
    0% {
        transform: rotate(-1deg);
    }
    50% {
        transform: rotate(1deg);
    }
    100% {
        transform: rotate(-1deg);
    }
}

@media (max-width: $p-mobile) {
}

@media (max-width: $tablet) {
    .header {
        &__info {
            margin: 10px 0px;
            &-wrapper {
                flex-direction: column;
                height: 300px;
            }
        }
        &__scan {
            &-button {
                margin: 10px 0px;
                img {
                    width: 50px;
                }
            }

            &-buttons-wrapper {
                flex-direction: column;
            }
        }

        &__buy {
            &-button-1 {
                margin: 10px 0px;
                flex: none;
            }

            &-button-2 {
                margin: 10px 0px;
                flex: none;
            }

            &-buttons-wrapper {
                flex-direction: column;
            }
        }
    }
}

@media (max-width: $desktop) {
}

@media (max-width: $l-desktop) {
}
