@import "src/styles/common.scss";

.team {
    padding: 100px 0px;
    h2 {
        justify-content: center;
    }

    &__container {
    }

    &__members {
        display: flex;
        column-gap: 50px;
        &-member {
            display: flex;
            width: 33%;
            flex-direction: column;
        }
        &-profile {
            border-radius: 50%;
            width: 100%;
            max-width: 200px;
            margin: auto;
            border: 1px solid $secondary-color;
        }
        &-name {
            margin-top: 15px;
            font-family: "Arial Black", Gadget, sans-serif;
            font-family: 20px;
            text-align: center;
        }
        &-position {
            text-align: center;
            font-size: 20px;
        }
        &-linkedin {
            width: 30px;
            margin: auto;
            margin: 5px auto;
        }

        &-description1 {
            margin: 20px 0px;
            height: 125px;
            font-size: 22px;
        }
        &-description2 {
            font-size: 22px;
            height: 300px;
        }
    }

    &__contributors {
        &-name {
            margin-top: 15px;
            font-family: 20px;
            font-weight: bold;
            text-align: center;
        }
    }
}

@media (max-width: $p-mobile) {
}

@media (max-width: $tablet) {
    .team {
        &__members {
            flex-direction: column;
            &-member {
                width: 100%;
            }
        }
    }
}

@media (max-width: $desktop) {
}

@media (max-width: $l-desktop) {
}
