@import "src/styles/common.scss";

.join-us {
    display: flex;
    align-items: center;
    height: 150px;
    left: 0;
    width: 100%;
    animation: ticker linear 20s infinite;
    &__container {
        height: 200px;
        justify-content: center;
        text-align: center;
        color: #d7c8ac;
        font-size: 22px;
        font-weight: bold;
    }
    &__wrapper {
        margin-top: 10px;
        overflow: hidden;
        height: 150px;
        position: absolute;
        width: 100%;
        background-color: white;
        left: 0;
        filter: drop-shadow(0px 0px 5px rgb(170, 139, 81, 0.3));
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 20px;
    }
    img {
        width: 300px;
        margin: 0px 30px;
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0);
            transition: 1s;
        }
    }
}

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@media (max-width: $p-mobile) {
    .info {
    }
}

@media (max-width: $tablet) {
}

@media (max-width: $desktop) {
    .info {
    }
}
