@import "src/styles/common.scss";

.particles {
    position: fixed;
    height: 100%;
    width: 100%;
}

.header-background {
    width: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-size: 100% 800px;
    background-position: center top;
}

@media screen and (max-width: $p-mobile), screen and (max-width: $tablet) {
    .particles {
        display: none;
    }

    .header-background {
        background-size: unset;
    }
}
