@import "src/styles/common.scss";

.navbar {
    &__container {
        display: flex;
        justify-content: center;
        position: fixed;
        margin-bottom: 75px;
        height: 75px;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: var(--theme-navbar-background);
        transition: 1s;
        width: 100%;
        z-index: 1000;
        -webkit-animation: fade-in-top 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in-top 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        background-color: $main-color;
    }

    &__logo {
        display: flex;
        align-items: center;
        transition: 1s;
        height: 75%;
        &-wrapper {
            display: flex;
            justify-content: flex-start;
            margin-left: 30px;
            height: 100%;
        }
        &-text {
            height: 30%;
            margin: 0px 10px;
        }
    }

    &__links {
        display: flex;
        justify-content: center;
        align-items: center;
        &-wrapper {
            display: flex;
            flex: 60%;
            justify-content: center;
        }
    }

    &__paper {
        display: flex;
        justify-content: center;
        align-items: center;
        &-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-right: 30px;
            height: 50%;
            align-items: center;
        }
    }

    &__hamburger-menu {
        display: none;
    }

    &__hamburger-menu-dropdown {
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 75px;
        min-width: 100%;
        justify-content: space-evenly;
        font-size: 30px;
        height: auto;
        z-index: 100;
        background-color: white;
        border-bottom-left-radius: 37px;
        border-bottom-right-radius: 37px;
        -webkit-animation: fade-in-top 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in-top 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        & * {
            margin: 20px 0px;
        }
    }
}

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: $p-mobile) {
    .navbar {
        &__logo {
            &-text {
                display: none;
            }
        }
    }
}

@media (max-width: $tablet) {
}

@media (max-width: $desktop) {
    .navbar {
        &__container {
            justify-content: space-between;
        }
        &__paper-wrapper {
            display: none;
        }

        &__hamburger-menu {
            display: block;
            margin: 0px 30px;
            cursor: pointer;
        }

        &__links-wrapper {
            display: none;
        }

        &__paper-wrapper {
            display: none;
        }
    }
}

@media (max-width: $l-desktop) {
    .navbar {
        &__paper-wrapper {
            display: none;
        }
    }
}
