$p-mobile: 575.98px;
$l-mobile: 767.98px;
$tablet: 991.98px;
$desktop: 1199.98px;
$l-desktop: 1399.98px;

$main-color: white;
$secondary-color: #582014;
$ternary-color: #b3aca0;
$quaternary-color: #4fc3f7;
$pentary-color: #ffa200;

.container {
    margin: auto;
    min-height: calc(100vh - 120px);
}
@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

h2 {
    display: flex;
    font-family: "Arial Black", Gadget, sans-serif;
    width: 100%;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 48px;
    text-align: left;
    filter: drop-shadow(0px 4px 0px #ffe4b2);
}

h3 {
    display: flex;
    font-family: "Arial Black", Gadget, sans-serif;
    justify-content: flex-start;
    margin: 10px 0px;
    font-size: 38px;
    text-align: left;
    filter: drop-shadow(0px 4px 0px #ffe4b2);
}

ul {
    padding: 0;
}

.component-container {
    display: flex;
    align-items: center;
    padding: 150px 0px 100px 0px;
    flex-direction: column;
    justify-content: center;
}

.button {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    color: $main-color;
    align-items: center;
    cursor: pointer;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    width: auto;
    padding: 0px 20px;
    justify-content: center;
    border-radius: 13px;
    margin: auto;

    &:focus {
        outline: 0;
    }

    &:active,
    &:hover {
        transform: scale(0.95);
        color: white;
        transition: 1s;
    }

    &--active {
        justify-content: center;
        transition: 1s;
        padding: 0px 20px;
        color: white;
        background-color: #ff5f67;
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    17% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
@media screen and (max-width: $desktop) {
    .column {
        flex: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    h2 {
        text-align: center;
        justify-content: center;
    }
}
@media (max-width: $p-mobile) {
    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }

    .component-container {
        height: 100%;
        padding: 50px 0px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 26px;
        text-align: center;
    }
}

@media (max-width: $tablet) {
    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }

    .component-container {
        height: 100%;
        padding: 50px 0px;
    }
}
