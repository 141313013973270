@import "src/styles/common.scss";

.faq {
    display: flex;
    align-items: center;
    flex-direction: column;
    word-break: break-all;
    &__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__background {
        background-color: #f2e5cd;
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    h2 {
        justify-content: center;
    }
    &__wrapper {
        display: flex;
        width: 80%;
        background-color: $main-color;
        border-radius: 23px;
        overflow: hidden;
        font-size: 22px;
        height: 90px;
        margin: 10px 0px;
        filter: drop-shadow(0px 5px 4px rgba(170, 139, 81, 0.5));
        flex-direction: column;
        height: 100%;
    }

    &__title {
        font-weight: bold;
        color: $secondary-color;
        cursor: pointer;
        display: flex;
        height: 100%;
        min-height: 90px;
        padding: 0px 50px;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        transition: 0.4s;
    }

    &__description {
        padding: 0px 50px 40px 50px;
        min-height: 70px;
        display: flex;
        color: $secondary-color;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        text-align: justify;
        -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: $p-mobile) {
}

@media (max-width: $tablet) {
    .faq {
        &__wrapper {
            width: 100%;
        }

        &__title {
            padding: 0px 25px;
        }

        &__description {
            padding: 0px 25px 40px 25px;
        }
    }
}

@media (max-width: $desktop) {
}

@media (max-width: $l-desktop) {
}
