@import "src/styles/common.scss";

.first-time {
    &__container {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        h2 {
            justify-content: center;
        }
    }

    &__wrapper {
        display: flex;
        column-gap: 30px;
        position: relative;
        &-token-img-1 {
            position: absolute;
            left: -22%;
            top: 24%;
            transform: rotate(-40deg);
            max-width: 300px;
        }
        &-token-img-2 {
            position: absolute;
            right: -22%;
            top: 52%;
            transform: rotate(10deg);
            z-index: 100;
            max-width: 300px;
        }
    }

    &__title {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        font-family: "Arial Black", Gadget, sans-serif;
        font-size: 20px;
        color: $pentary-color;
        margin: 30px 0px;
        white-space: pre;
        align-items: center;
        &-cex {
            color: #d1bf9f;
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    &__stickers {
        &-container {
            display: flex;
            flex-direction: column;
            flex: 65%;
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;
        }

        &-sticker {
            border-radius: 32px;
            filter: drop-shadow(0px 3px 5px rgb(162, 162, 162, 0.6));
            flex: 24%;
            display: flex;
            background: white;
            flex-direction: column;
            align-items: center;
            font-size: 20px;
            text-align: center;
            padding: 20px;
            margin-bottom: 30px;
            position: relative;
            &-number {
                position: absolute;
                top: 15px;
                left: 15px;
                color: $pentary-color;
                font-family: "Arial Black", Gadget, sans-serif;
                font-size: 16px;
            }
            img {
                position: absolute;
                top: -18px;
                right: -18px;
                width: 65px;
            }
            .button {
                font-size: 14px;
                padding: 0px 10px;
            }
        }

        &-text-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        &-title {
            font-family: "Arial Black", Gadget, sans-serif;
            font-size: 18px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-description {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            height: 130px;
            margin: 20px 0px;

            &-contract {
                a {
                    display: inline;
                    white-space: pre;
                    color: #4f83e2;
                }
            }

            span {
                margin-bottom: 20px;
            }
        }
    }

    &__cexes {
        padding: 30px;
        &-container {
            display: flex;
            flex: 35%;
            flex-direction: column;
        }

        &-wrapper {
            height: 100%;
            width: 100%;
            margin-bottom: 20px;
            border-radius: 33px;
            box-shadow: inset 0px 0px 20px rgba(52, 42, 1, 0.4);
            &-list {
                display: flex;
                flex-direction: column;
                font-size: 16px;
                font-weight: bold;
                span {
                    margin: 3px 0px;
                }
            }
        }

        &-title {
            font-family: "Arial Black", Gadget, sans-serif;
            margin: 20px 0px;
        }

        &-image-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 20px;
            flex-wrap: wrap;
            a {
                height: 60px;
                display: flex;
                width: 38%;
                background-color: white;
                margin-bottom: 20px;
                padding: 0px 10px;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: $desktop) {
    .first-time {
        &__wrapper {
            flex-direction: column;
        }
        &__stickers {
            &-sticker {
                flex: 25%;
            }
        }
    }
}

@media (max-width: $tablet) {
    .first-time {
        &__wrapper {
            flex-direction: column;
            &-token-img-1,
            &-token-img-2 {
                display: none;
            }
        }
        &__stickers {
            &-sticker {
                flex: 40%;
            }
        }

        &__title {
            justify-content: center;
            text-align: center;
        }
    }
}

@media (max-width: $p-mobile) {
    .first-time {
    }
}
